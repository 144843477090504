* {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

:root {
  --page-height: 80vh;
  --navbar-height: 75px;
  --logo-width: 70px;
  --footer-height: 30vh;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App-loading-screen {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-loading-screen-container {
  width: fit-content;
  height: fit-content;
}

.App-loading-screen-logo {
  width: 10rem;
  animation: load-opacity 2.1s linear;
}

.App-loading-screen-bar {
  width: 10rem;
  height: 0.5vh;
  background-color: rgb(166, 166, 166);
  animation: load-opacity 2.1s linear;
}

.App-loading-screen-bar-progress {
  position: relative;
  top: -0.5vh;
  height: 0.5vh;
  background-color: black;
  animation: load-bar 2.1s linear;
  align-self: flex-start;
}

@keyframes load-opacity {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
}

@keyframes load-bar {
  0% {
    width: 0;
  }
  90% {
    width: 10rem;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}