@import url('../App.css');

.contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 80vw;
    height: fit-content;
    margin: 10vh;
}

.contact-title {
    font-size: 6rem;
    align-self: flex-start;
    width: 65%;
    text-align: left;
    margin: 0;
    position: relative;
}

.contact-alternatives {
    align-self: flex-start;
    gap: 5px;
    width: 100%;
    text-align: left;
    position: relative;
}

.contact-alternatives-text {
    font-size: 1rem;
    font-weight: 300;
    color: black;
}

.contact-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contact-posco {
    width: 50%;
    height: 40vh;
    object-fit: cover;
    object-position: 80% 50%;
}

.contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
    margin: 5vh;
}

.contact-form-input {
    border: none;
    border-bottom: 1px solid black;
    padding: 10px;
    width: 100%;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.contact-textarea {
    resize: none;
}

.contact-form-submit {
    width: 13%;
    align-self: start;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
}

@media screen and (max-width: 1850px) {
    .contact-form-submit {
        width: 25%;
    }
}
@media screen and (max-width: 1210px) {
    .contact-form-submit {
        width: 40%;
    }
}

@media screen and (max-width: 1050px) {
    .contact {
        height: fit-content;
        margin: 5vh 0 5vh;
    }
    .contact-title {
        font-size: 4rem;
    }
    .contact-alternatives-text {
        font-size: 0.75rem;
    }
    .contact-body {
        flex-direction: column;
    }
    .contact-posco {
        width: 100%;
    }
    .contact-form {
        width: 100%;
        margin: 2vh;
    }
    .contact-form-input {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 650px) {
    .contact-title {
        font-size: 3rem;
    }
    .contact-alternatives-text {
        font-size: 0.6rem;
    }
    .contact-form-input {
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 475px) {
    .contact-title {
        font-size: 2.25rem;
    }
}

@media screen and (max-width: 380px) {
    .contact-title {
        font-size: 2rem;
    }
}

@media screen and (orientation: landscape) {
    .contact-body {
        margin-bottom: 10vh;
    }
}