@import '../App.css';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    padding: 5rem 10rem 0.25rem 10rem;
}

.navbar-top {
    position: fixed;
    opacity: 0.97;
    top: 0;
    left: 0;
    width: 100vw;
    height: 0vh;
    background-color: black;
    z-index: 2;
    transition: height 0.4s cubic-bezier(.23,1,.59,1.01);
}

.navbar-top.clicked {
    height: 100vh;
}

.navbar-logo {
    display: flex;
    margin-left: 1rem;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.navbar-logo img {
    width: var(--logo-width);
}

.navbar-right {
    display: flex;
}

.navbar-burger-container {
    height: 28px;
    width: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.navbar-burger,
.navbar-burger::before,
.navbar-burger::after {
    display: block;
    height: 4px;
    width: 30px;
    border-radius: 5px;
    background-color: black;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center;
}

.navbar-burger.clicked {
    background: rgba(255, 255, 255, 0);
}

.navbar-burger::before {
    content: '';
    position: relative;
    top: -10px;
}

.navbar-burger.clicked:before {
    top: 0;
    transform: rotate(405deg);
    background-color: white;
}

.navbar-burger::after {
    content: '';
    position: relative;
    top: 6px;
}

.navbar-burger.clicked::after {
    top: -3.5px;
    transform: rotate(-405deg);
    background-color: white;
}

.navbar-links {
    position: fixed;
    left: 60vw;
    top: 0;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40vw;
    height: 100vh;
    z-index: 2;
}

.navbar-link {
    padding: 0.6rem;
    text-decoration: none;
    color: white;
    font-size: 4rem;
    font-weight: 400;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

@media screen and (max-width: 1500px) {
    .navbar {
        padding: 5rem 8rem 0.25rem 8rem;
    }
}

@media screen and (max-width: 1200px) {
    .navbar {
        padding: 5rem 5rem 0.25rem 5rem;
    }
    .navbar-links {
        left: 20vw;
        width: 80vw;
    }
    .navbar-link {
        font-size: 3rem;
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 3rem 2.5rem 0.25rem 2rem;
    }
    .navbar-logo img {
        width: 60px;
    }
}