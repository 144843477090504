@import url('../App.css');

.project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    margin: 10vh 0 15vh;
}

.project-title {
    align-self: flex-start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    left: 10vw;
    margin: 0 0 1.6rem;
    gap: 0.25rem;
}

.project-name {
    font-size: 4rem;
    margin: 0;
}

.project-location-size-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.project-location,
.project-tag,
.project-split,
.project-size,
.project-description,
.project-date {
    font-size: 2rem;
    font-weight: 350;
    margin: 0;
}

.project-description {
    font-weight: 350;
    margin: 0.3rem 0.3rem;
    position: relative;
    left: -0.3rem;
}

.project-date {
    font-weight: 350;
}

.project-image {
    width: 80vw;
    height: 70vh;
    object-fit: cover;
}

.project-others-link {
    align-self: flex-start;
    position: relative;
    left: 10vw;
    margin: 2rem 0 0;
    font-size: 3rem;
    color: black;
}

@media screen and (max-width: 1000px) {
    .project-name {
        font-size: 3rem;
    }
    .project-location,
    .project-tag,
    .project-split,
    .project-size,
    .project-description,
    .project-date {
        font-size: 1.5rem;
    }
    .project-location-size-container {
        gap: 0.75rem;
    }
}

@media screen and (max-width: 700px) {
    .project-name {
        font-size: 2.5rem;
    }
    .project-location,
    .project-tag,
    .project-split,
    .project-size,
    .project-description,
    .project-date {
        font-size: 1.25rem;
    }
    .project-location-size-container {
        gap: 0.5rem;
    }
    .project-others-link {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 550px) {
    .project-name {
        font-size: 7vw;
    }
    .project-location,
    .project-tag,
    .project-split,
    .project-size,
    .project-description,
    .project-date {
        font-size: 4vw;
    }
    .project-location-size-container {
        gap: 1vw;
    }
    .project-date {
        margin-top: -0.2rem;
    }
    .project-others-link {
        font-size: 2rem;
    }
}