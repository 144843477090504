@import '../App.css';

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    width: 100vw;
    height: var(--footer-height);
}

.footer-nav {
    display: flex;
    gap: 1rem;
    color: #333333;
}

.footer-nav-link {
    text-decoration: none;
    color: #333333;
    font-weight: 400;
}

.footer-contacts-phone-and-email {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-contacts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer-contacts-content {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: #333333;
}

.footer-logo img {
    width: var(--logo-width);
}

@media screen and (max-width: 900px) {
    .footer-contacts-content,
    .footer-nav-link {
        font-size: 0.75rem;
    }
    .footer-logo img {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 700px) {
    .footer-contacts-content,
    .footer-nav-link {
        font-size: 0.65rem;
    }
    .footer-logo img {
        transform: scale(0.8);
    }
}