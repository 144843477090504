@import '../App.css';

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--page-height);
}

.about-title {
    font-size: 7em;
    position: relative;
    left: -20%;
}

.about-intro {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: 30vh;
}

.about-intro-text {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: left;
    position: relative;
    top: -7em;
    width: 32%;
    align-self: last baseline;
}

.about-posco-photo {
    width: 60%;
    height: 50vh;
    object-fit: cover;
    position: relative;
    top: -10em;
}

.about-jung-sang-cho {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 10rem;
    position: relative;
    left: -10%;
}

.about-jung-sang-cho-text {
    display: block;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 300;
    width: 40%;
    margin: 1rem;
    position: relative;
    top: 0.25em;
    align-self: last baseline;
}

.about-jung-sang-cho-photo {
    width: 25vw;
    height: 50vh;
    object-fit: cover;
}

.about-hang-cho {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    margin: 10rem;
    position: relative;
    left: 10%;
}

.about-hang-cho-text {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 300;
    width: 40%;
    position: relative;
    top: 0.25em;
    align-self: last baseline;
}

.about-hang-cho-photo {
    width: 25vw;
    height: 50vh;
    object-fit: cover;
    object-position: 50% 15%;
}

@media screen and (max-width: 1200px) {
    .about-title-container {
        width: 70vw;
    }
    .about-title {
        left: 0;
        font-size: 5rem;
    }
    .about-intro {
        flex-direction: column-reverse;
        gap: 0;
        margin-bottom: 10rem;
    }
    .about-posco-photo {
        width: 70vw;
        top: 0;
    }
    .about-intro-text {
        width: 70vw;
        font-size: 1.25rem;
        top: 0;
    }
    .about-jung-sang-cho {
        flex-direction: column;
        gap: 0;
        left: 0;
    }
    .about-jung-sang-cho-photo {
        width: 70vw;
    }
    .about-jung-sang-cho-text {
        width: 70vw;
        font-size: 1.25rem;
    }
    .about-hang-cho {
        left: 0;
        flex-direction: column-reverse;
        gap: 0;
    }
    .about-hang-cho-photo {
        width: 70vw;
    }
    .about-hang-cho-text {
        width: 70vw;
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 600px) {
    .about-title {
        font-size: 3rem;
    }
}