@import '../App.css';

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: fit-content;
}

.home-motto-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: var(--page-height);
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.home-motto-text {
    font-size: 6rem;
    margin: 0;
    position: relative;
    left: 15%;
}

.home-motto-text-2 {
    font-size: 4rem;
    font-style: italic;
    font-weight: 500;
    margin: 0;
    position: relative;
    left: 15%;
}

.home-projects-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-top: 20vh;
    margin-bottom: 15vh;
}

.home-projects-preview-text {
    text-align: left;
    position: relative;
    left: 10vw;
    font-size: 4rem;
    margin: 0 0 3rem 0;
}

.home-swiper {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.home-swiper-slide {
    display: contents;
    position: relative;
    width: 30%;
    height: 60vh;
    margin: 0;
}

.home-swiper-image {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    transition: 0.2s cubic-bezier(.25,.1,.25,1);
    object-position: 50% 50%;
}

.home-swiper-content {
    text-align: left;
    font-size: 2rem;
    font-weight: 400;
    transition: 0.2s cubic-bezier(.25,.1,.25,1);
    margin: 0;
    color: black;
}

.home-swiper-content-subtitle {
    text-align: left;
    font-size: 1.25rem;
    margin: 3px;
}

.home-swiper-image:hover {
    object-position: 40% 50%;
}

.home-swiper-buttons {
    margin: 2rem 0 0 0;
    display: flex;
    gap: 0.5rem;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.home-swiper-prev,
.home-swiper-next {
    width: 6rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .home-motto {
        left: 0;
        align-items: center;
        justify-content: center;
    }
    .home-motto-container {
        width: fit-content;
    }
    .home-motto-text {
        left: 0;
        font-size: 9vw;
    }
    .home-motto-text-2 {
        left: 0;
        font-size: 8vw;
    }
    .home-swiper {
        left: 0;
        width: 80%;
    }
    .home-projects-preview-text {
        font-size: 4rem;
        text-align: center;
        left: 0;
        margin: 2rem;
    }
    .home-swiper-image {
        object-position: 60% 50%;
    }
    .home-swiper-content {
        font-size: 1.5rem;
    }
    .home-swiper-content-subtitle {
        font-size: 0.9rem;
    }
    .home-swiper-prev,
    .home-swiper-next {
        width: 50%;
        height: 2.5rem;
    }
}

@media screen and (max-width: 800px) {
    .home-projects-preview-text {
        font-size: 3rem;
    }
}

@media screen and (max-width: 550px) {
    .home-projects-preview-text {
        font-size: 2rem;
    }
}