@import '../App.css';

.projects {
    width: 100vw;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.projects-title-container {
    width: 100%;
    height: var(--page-height);
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-title {
    position: relative;
    left: -20%;
    font-size: 7rem;
}

.projects-grid-container {
    margin: 30vh 0 15vh;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;
    width: 80%;
}

@media screen and (max-width: 1500px) {
    .projects-grid-container {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 1100px) {
    .projects-title {
        font-size: 10vw;
        left: 0;
    }
    .projects-grid-container {
        grid-template-columns: auto;
    }
}